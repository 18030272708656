import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Alan Crissey, WebMaster" />
      <section className="wrapper">
        <p>
          Hi.{' '}
          <span role="img" aria-label="wave">
            👋
          </span>{' '}
          I’m Alan Crissey.
        </p>
        <p>
          In my current incarnation I’m a Senior Software Engineer at{' '}
          <a href="https://mailchimp.com/" rel="nofollow">
            Mailchimp
          </a>{' '}
          with a focus in frontend. Building accessible UI for websites and web
          apps is a passion of mine, but not the only one. It’s a culmination of
          several past careers / experiences / successes / failures. My brain is
          a full harddrive.
        </p>
        <h2>Currently</h2>
        <ul>
          <li>game dev hobbyist</li>
          <li>analog synth nut</li>
          <li>human dog chew toy holder</li>
        </ul>
        <h2>Formerly</h2>
        <ul>
          <li>graphic & web designer (BFA in Graphic Design)</li>
          <li>touring musician in a band</li>
          <li>Flash developer & animator</li>
        </ul>
      </section>
      <section className="wrapper">
        <h2>Pixeltrash</h2>
        <ul>
          <li>
            <Link to="/pixeltrash/dvd-screensaver">DVD Screensaver</Link>
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export default IndexPage;
